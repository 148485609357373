.swiper {
  width: 100%;
  height: 100%;
    border-radius: 4px;

}
:root {
    --swiper-navigation-size: 24px;
}

.swiper-slide {
  text-align: center;
  font-size: 1.125rem;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  
}
.swiper-button-prev {
    color: #fff;
    font-size: "0.875rem !important";
    font-family: 'NotoSans-Bold'; 
    font-weight: bolder;

}

.swiper-button-next {
    color: #fff; 
     font-size: "0.875rem !important";
    font-family: 'NotoSans-Bold'; 
        font-weight: bolder;

}
.swiper-button-next:after, .swiper-button-prev:after {
font-size: "0.875rem !important";
}