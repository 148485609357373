.react-datepicker {
    font-family: "NotoSans-SemiBold";
    background-color: #fff;
    color: #000;
    border: 1px solid #e4e8ee;
    display: inline-block;
    position: relative;
    border-radius: 16px;
    box-shadow: 0px 2px 12px #00000014;
    width: 100%;
  }
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    border-radius: 16px !important;
    /* padding: 16px; */
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day {
    border-radius: 16px;
  }
  
  .react-datepicker__day--keyboard-selected {
    color: blue;
    background-color: #f2f8ff;
    border: 1px solid #1f74ea;
  }
  
  .react-datepicker__day--selected {
    color: blue;
    background-color: #f2f8ff;
    border: 1px solid #1f74ea;
  }
  .react-datepicker__day--selected:hover {
    color: blue;
    background-color: #f2f8ff;
    border: 1px solid #1f74ea;
    border-radius: 16px;
  }
  .react-datepicker__day.react-datepicker__day--today:hover {
    color: blue;
    background-color: #f2f8ff;
    border: 1px solid #1f74ea;
    border-radius: 16px;
  }
  .react-datepicker__navigation--next {
    top: 5px;
    right: 13px;
    border-radius: 19px;
    background-color: rgb(255 255 255);
    border: 1px solid #e4e8ee;
  }
  .react-datepicker__navigation-icon--next {
    left: 2px;
  }
  .react-datepicker__navigation-icon--previous {
    right: 2px;
  }
  .react-datepicker__navigation--previous {
    top: 5px;
    left: 13px;
    border-radius: 19px;
    background-color: rgb(255 255 255);
    border: 1px solid #e4e8ee;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #040404;
    border: 0px solid;
    border-width: 1px 1px 0px 0px;
    content: "";
    display: block;
    height: 7px;
    position: absolute;
    top: 9px;
    width: 7px;
  }
  .react-datepicker__day-names {
    margin-top: 10px;
  }
  .react-datepicker__current-month {
    font-size: 0.875rem;
    font-family: "NotoSans-Bold";
  }
  
  .react-datepicker-popper.example-datepicker-class {
    position: absolute;
    height: 352px;
  }

  .react-datepicker__month-container {
    /* border-right: 1px solid #E4E8EE; */
    /* margin-top: 5px; */
    width: 351px;
    padding: 16px;
    
  }
  
  .MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }
  