::-webkit-scrollbar {
  width: 6px !important;
}

/* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Hide Number Input Roller */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important;
}

/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
/* @font-face {
  font-family: "NotoSans-Bold";
  src: url("./font/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "NotoSans-SemiBold";
  src: url("./font/NotoSans-SemiBold.ttf");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./font/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "NotoSans-ExtraBold";
  src: url("./font/NotoSans-ExtraBold.ttf");
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url("./font/NotoSans-Italic.ttf");
} */

@font-face {
  font-family: "NotoSans-Bold";
  src: url("./font/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./font/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "NotoSans-Light";
  src: url("./font/NotoSans-Light.ttf");
}

@font-face {
  font-family: "NotoSans-ExtraBold";
  src: url("./font/NotoSans-ExtraBold.ttf");
}

@font-face {
  font-family: "NotoSans-Medium";
  src: url("./font/NotoSans-Medium.ttf");
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url("./font/NotoSans-Italic.ttf");
}

@font-face {
  font-family: "NotoSans-SemiBold";
  src: url("./font/NotoSans-SemiBold.ttf");
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
html {
  height: 100%;
}

#root {
  height: 100%;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.texthead1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unittext1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html[dir="rtl"] {
  font-size: 110% !important;
}

html[dir="ltr"] {
  font-size: 100% !important;
}
.css-19giel2-menu{
  z-index: 9;
}
.MuiTypography-root {
  letter-spacing: 0;
}