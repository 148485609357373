/* change background and tip color to yellow */
.popup .mapboxgl-popup-content {
    width: 360px;
    border-radius: 10px;
    padding: 10px;
  }

  .popup .mapboxgl-popup-close-button {
    font-size: 1.625rem;
    margin-right: 4px;
    color: #929AA6;
  }

 .mapboxgl-popup{
  max-width: 360px !important;
}
  .popupSingle .mapboxgl-popup-content {
    width: fit-content;
    border-radius: 10px;
    padding: 5px;
    max-width: 430px;
  }

  .popupopupSinglep .mapboxgl-popup-close-button {
    font-size: 1.625rem;
    margin-right: 4px;
    color: #929AA6;
  }
  .cluster-marker {
  color: #fff;
  background: rgb(120, 177, 254);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: "NotoSans-SemiBold";
}
